import { createI18n } from "vue-i18n";
import { AuthModule } from "@/store/modules/auth";
import messages from "../../i18n";

export const i18n = createI18n({
  legacy: false,
  locale: AuthModule.getDisplayLang,
  missingWarn: false,
  fallbackWarn: false,
  messages,
});
