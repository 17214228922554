import Vuex from "vuex";
import { AuthState } from "./modules/auth";
import VuexPersistence from "vuex-persist";
import { BreadcrumbState } from "@/store/modules/breadcrumbs";

export interface IRootState {
  auth: AuthState;
  breadcrumb: BreadcrumbState;
}

const vuexLocal = new VuexPersistence<IRootState>({
  storage: window.localStorage,
});

// Use this store for modules not requiring persistence
export const temporaryStore = new Vuex.Store<IRootState>({});

export default new Vuex.Store<IRootState>({
  plugins: [vuexLocal.plugin],
});
