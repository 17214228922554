<template>
  <div class="q-px-md">
    <q-card class="q-py-lg q-mx-auto" align="center" style="width: 60em">
      <q-card-section>
        <div class="text-h5">
          {{ $t("views.setpassword.title") }}
        </div>
      </q-card-section>

      <q-card-section>
        <div class="q-px-md">
          <q-input
            v-model="password1"
            :type="passwordShow ? 'text' : 'password'"
            :label="$t('views.setpassword.password')"
          >
            <template #prepend>
              <q-icon name="fas fa-key" size="s" />
            </template>
          </q-input>
          <br />
          <q-input
            v-model="password2"
            :type="passwordShow ? 'text' : 'password'"
            :label="$t('views.setpassword.confirm')"
            bottom-slots
          >
            <template #append>
              <q-icon
                :name="passwordShow ? 'visibility' : 'visibility_off'"
                class="cursor-pointer"
                @click="passwordShow = !passwordShow"
              />
            </template>
            <template #prepend>
              <q-icon name="fas fa-key" size="s" />
            </template>
          </q-input>
        </div>
      </q-card-section>

      <q-card-section align="left" class="q-ml-md light-dimmed">
        {{ $t("views.setpassword.check4") }} : <br />
        <q-icon name="check_circle" /> {{ $t("views.setpassword.check") }}<br />
        <q-icon name="check_circle" /> {{ $t("views.setpassword.check2")
        }}<br />
        <q-icon name="check_circle" /> {{ $t("views.setpassword.check3") }}
      </q-card-section>

      <q-separator />

      <q-card-actions align="right">
        <q-btn
          flat
          :label="$t('views.setpassword.submit')"
          type="submit"
          color="primary"
          @click="setPassword()"
        />
      </q-card-actions>
    </q-card>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useQuasar } from "quasar";
import { authApi } from "@/api/ilect-openapi-configuration";
import { PasswordResetConfirm } from "@/openapi-ts";

export default defineComponent({
  name: "SetPassword",

  setup() {
    const $q = useQuasar();
    const $route = useRoute();
    const $router = useRouter();
    const password1 = ref("");
    const password2 = ref("");
    const passwordShow = ref(false);
    const uid = $route.query.uid as string;
    const token = $route.query.token as string;

    async function setPassword() {
      if (password1.value !== password2.value) {
        $q.notify({
          message: "Passwords do not match",
          color: "negative",
          icon: "error",
          timeout: 2000,
        });
        return;
      }

      const newPassword: PasswordResetConfirm = {
        new_password1: password1.value,
        new_password2: password2.value,
        uid: uid,
        token: token,
      };

      try {
        await authApi.authPasswordResetConfirmCreate(newPassword);
      } catch (error) {
        if (error.response && error.response.status === 400) {
          $q.notify({
            message: error.response.data.new_password2[0],
            color: "negative",
            icon: "error",
            timeout: 2000,
          });
          console.error(error);
        }
        return;
      }

      $q.notify({
        message: "Password set successfully",
        color: "positive",
        icon: "check",
        timeout: 2000,
      });
      setTimeout(() => $router.push({ path: "/login" }), 2000);
    }

    return {
      password1,
      password2,
      setPassword,
      passwordShow,
    };
  },
});
</script>
