<template>
  <div class="q-px-md q-pt-md q-gutter-sm">
    <q-breadcrumbs>
      <template #separator>
        <q-icon size="2em" name="arrow_right" />
      </template>
      <q-breadcrumbs-el
        v-for="breadcrumb in breadcrumbs"
        :key="breadcrumb.label"
        class="text-bold"
        :label="$t(breadcrumb.label)"
        :icon="useIcon && breadcrumb.icon ? breadcrumb.icon : undefined"
        :to="breadcrumb.to ? breadcrumb.to : undefined"
      />
    </q-breadcrumbs>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { Breadcrumb } from "@/router/breadcrumbs";
import { BreadcrumbModule } from "@/store/modules/breadcrumbs";
import { useI18n } from "vue-i18n";

/*
 * This component is used to display the breadcrumbs.
 * It generates the breadcrumbs from the route's breadcrumbGenerator.
 * Edit breadcrumb generator to change the breadcrumbs.
 * */
export default defineComponent({
  name: "BreadcrumbView",
  setup() {
    const $route = useRoute();
    const breadcrumbs = ref<Breadcrumb[]>([]);
    const useIcon = ref(false);
    const { locale } = useI18n();
    watch(
      () => [
        $route.path,
        locale,
        // We need to watch the fields in the vuex, since they may be updated by async
        // We may be able to use action mutations to circumvent this
        BreadcrumbModule.courseName,
        BreadcrumbModule.forumTitle,
        BreadcrumbModule.supportTitle,
        BreadcrumbModule.assignmentName,
        BreadcrumbModule.quizName,
        BreadcrumbModule.submissionName,
        BreadcrumbModule.videoTitle,
      ],
      () => {
        if ($route.meta.breadcrumbGenerator) {
          breadcrumbs.value = $route.meta.breadcrumbGenerator($route);
        } else {
          breadcrumbs.value = [];
        }
      },
    );

    return {
      breadcrumbs,
      useIcon,
    };
  },
});
</script>
