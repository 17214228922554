import axios, { AxiosResponse, AxiosError } from "axios";
import { VueGapi } from "vue-gapi";
import * as interfaces from "@/api/interfaces";
import { userGoogleDriveIdApi } from "@/api/ilect-openapi-configuration";
import { Course } from "@/openapi-ts";

export class GoogleDriveManager {
  private $gapi: VueGapi;
  private gapiClient: any;

  constructor(gapi: VueGapi) {
    this.$gapi = gapi;
  }

  getGapiClient(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.$gapi
        .getGapiClient()
        .then((res: any) => {
          this.gapiClient = res;
          resolve();
        })
        .catch((error: Error) => {
          reject(error);
        });
    });
  }

  getGoogleDriveRootDirId(): Promise<string> {
    return new Promise((resolve, reject) => {
      axios
        .get<interfaces.UserGoogleDriveIdDetailedResponse>(
          `/api/UserGoogleDriveId/root/`,
        )
        .then((res) => {
          resolve(res.data.id);
        })
        .catch((error: AxiosError<interfaces.Error>) => {
          reject(error);
        });
    });
  }

  //
  // This function returns last response from the backend server when the action succeeds.
  //
  createGoogleDriveRootDirIfNotExists(): Promise<
    AxiosResponse<interfaces.UserGoogleDriveIdDetailedResponse>
  > {
    return new Promise((resolve, reject) => {
      const createRootDir = () => {
        return new Promise((resolve, reject) => {
          this.gapiClient.client.drive.files
            .create({
              name: "iLect-CL Contents",
              mimeType: "application/vnd.google-apps.folder",
              fields: "id",
            })
            .then((res: any) => {
              // POST the id of the root dir.
              axios
                .post<interfaces.UserGoogleDriveIdDetailedResponse>(
                  "/api/UserGoogleDriveId/",
                  { id: res.result.id, is_root: true },
                )
                .then((res) => {
                  resolve(res);
                })
                .catch((error: AxiosError<interfaces.Error>) => {
                  reject(error);
                });
            });
        });
      };

      userGoogleDriveIdApi
        .userGoogleDriveIdRootRetrieve()
        .then((rootResponse) => {
          // Check whether id stored in the backend server is valid or not.
          this.gapiClient.client.drive.files
            .get({
              fileId: rootResponse.data.id,
            })
            .then((_res: any) => {
              resolve(_res);
            })
            .catch((error: any) => {
              // If the root dir is deleted (completely).
              if (error.status == 404) {
                createRootDir().then((res: any) => {
                  resolve(res);
                });
              } else {
                reject(error);
              }
            });
        })
        .catch((error: AxiosError) => {
          let errorMessage: string = "";
          // If the root dir is not created yet. (First time login)
          if (typeof error.response?.data === "string") {
            errorMessage = error.response.data;
          }
          // let errorMessage: String = error.response?.data
          if (
            errorMessage === "root not found" &&
            error.response?.status === 404
          ) {
            createRootDir()
              .then((res: any) => {
                resolve(res);
              })
              .catch((error: any) => {
                reject(error);
              });
          } else {
            reject(error);
          }
        });
    });
  }

  createGoogleDriveCourseRootDirIfNotExists(
    course: Course,
  ): Promise<AxiosResponse<interfaces.UserGoogleDriveIdDetailedResponse>> {
    return new Promise((resolve, reject) => {
      const createCourseRootDir = (): Promise<
        AxiosResponse<interfaces.UserGoogleDriveIdDetailedResponse>
      > => {
        return new Promise((resolve, reject) => {
          this.getGoogleDriveRootDirId().then((rootId) => {
            this.gapiClient.client.drive.files
              .create({
                name: `Course ${course.title}`,
                mimeType: "application/vnd.google-apps.folder",
                fields: "id",
                parents: [rootId],
              })
              .then((res: any) => {
                // POST the id of the root dir.
                axios
                  .post<interfaces.UserGoogleDriveIdDetailedResponse>(
                    "/api/UserGoogleDriveId/",
                    { id: res.result.id, course: course.id, is_root: false },
                  )
                  .then((res) => {
                    resolve(res);
                  })
                  .catch((error: AxiosError<interfaces.Error>) => {
                    reject(error);
                  });
              });
          });
        });
      };

      axios
        .get<interfaces.UserGoogleDriveIdDetailedResponse>(
          "/api/UserGoogleDriveId/course/",
          { params: { course_id: course.id } },
        )
        .then((res) => {
          const oldId = res.data.id;
          this.gapiClient.client.drive.files
            .get({
              fileId: res.data.id,
            })
            .then(() => {
              resolve(res);
            })
            .catch((error: any) => {
              // If the root dir is deleted (completely).
              if (error.status == 404) {
                axios
                  .delete<void>(`/api/UserGoogleDriveId/${oldId}`)
                  .then(() => {
                    createCourseRootDir()
                      .then((res: any) => {
                        resolve(res);
                      })
                      .catch((err: any) => {
                        reject(err);
                      });
                  });
              } else {
                reject(error);
              }
            });
        })
        .catch((error: AxiosError<interfaces.Error>) => {
          // If the root dir is not created yet. (First time login)
          if (error.response?.status == 404) {
            createCourseRootDir()
              .then((res) => {
                resolve(res);
              })
              .catch((error: any) => {
                reject(error);
              });
          } else {
            reject();
          }
        });
    });
  }

  givePermissionsOfUserGoogleDriveToServiceAccount(
    course: Course,
  ): Promise<AxiosResponse<interfaces.UserGoogleDriveIdDetailedResponse>> {
    return new Promise((resolve, reject) => {
      // Get the id of the course root dir.
      axios
        .get<interfaces.UserGoogleDriveIdDetailedResponse>(
          "/api/UserGoogleDriveId/course/",
          { params: { course_id: course.id } },
        )
        .then((res) => {
          const request_body = {
            emailAddress: course.gapi_sa_email,
            type: "user",
            role: "writer",
          };

          // Update permissions to allow the service account to edit.
          this.gapiClient.client.drive.permissions
            .create({
              fileId: res.data.id,
              resource: request_body,
            })
            .then((res: any) => {
              resolve(res);
            })
            .catch((err: any) => {
              reject(err);
            });
        })
        .catch((err: AxiosError<interfaces.Error>) => {
          reject(err);
        });
    });
  }
}
