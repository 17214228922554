<template>
  <div class="separator text-grey">
    {{ text }}
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "SeparatorWithText",

  props: {
    text: {
      type: String,
      required: true,
    },
  },

  setup() {},
});
</script>

<style lang="sass">
.separator
  display: flex
  align-items: center
  text-align: center

  &::before, &::after
    content: ''
    flex: 1
    border-bottom: 1px solid
    border-bottom-color: #A1A1A1

  &:not(:empty)
    &::before
      margin-right: .25em

    &::after
      margin-left: .25em
</style>
