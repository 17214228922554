import {
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";
import { temporaryStore } from "@/store";
import {
  assignmentApi,
  courseApi,
  forumApi,
  supportApi,
  submissionApi,
  quizApi,
} from "@/api/ilect-openapi-configuration";
import router from "@/router";

// Is this interface needed?
export interface BreadcrumbState {
  courseName: string;
  assignmentName: string;
  // Computed from assignment name and username
  submissionName: string;
  forumTitle: string;
  supportTitle: string;
  videoTitle: string;
}

// Used to store data required for breadcrumbs.
@Module({
  dynamic: true,
  store: temporaryStore,
  namespaced: true,
  name: "breadcrumb",
  preserveState: false,
})
export default class Breadcrumb extends VuexModule implements BreadcrumbState {
  public courseName = "";
  public assignmentName = "";
  public quizName = "";
  public submissionName = "";
  public forumTitle = "";
  public supportTitle = "";
  public videoTitle = "";

  @Mutation
  public setCourseName(courseName: string) {
    this.courseName = courseName;
  }

  @Mutation
  public setAssignmentName(assignmentName: string) {
    this.assignmentName = assignmentName;
  }

  @Mutation
  public setQuizName(quizName: string) {
    this.quizName = quizName;
  }

  @Mutation
  public setSubmissionName(submissionName: string) {
    this.submissionName = submissionName;
  }

  @Mutation
  public setForumTitle(forumTitle: string) {
    this.forumTitle = forumTitle;
  }

  @Mutation
  public setSupportTitle(supportTitle: string) {
    this.supportTitle = supportTitle;
  }

  @Mutation
  public setVideoTitle(videoTitle: string) {
    this.videoTitle = videoTitle;
  }

  public get getCourseName() {
    return () => {
      // When the coursename is not set, we try to retrieve it using the api
      // This happens when the user refreshes the page
      if (!this.courseName) {
        const route = router.currentRoute.value;

        if (!("course_id" in route.params)) {
          return "";
        }

        courseApi
          .courseRetrieve(route.params.course_id as string)
          .then((res) => {
            // You have to use the module instead of `this`? bug?
            BreadcrumbModule.setCourseName(res.data.title);
            return this.courseName;
          });
      }
      return this.courseName;
    };
  }

  public get getAssignmentName() {
    return () => {
      if (!this.assignmentName) {
        const route = router.currentRoute.value;

        if (!("assignment_id" in route.params)) {
          return "";
        }

        assignmentApi
          .assignmentRetrieve(route.params.assignment_id as string)
          .then((res) => {
            BreadcrumbModule.setAssignmentName(res.data.title);
            return this.assignmentName;
          });
      }
      return this.assignmentName;
    };
  }

  public get getQuizName() {
    return () => {
      if (!this.quizName) {
        const route = router.currentRoute.value;

        if (!("quiz_id" in route.params)) {
          return "";
        }

        quizApi.quizRetrieve(Number(route.params.quiz_id)).then((res) => {
          BreadcrumbModule.setQuizName(res.data.title);
          return this.quizName;
        });
      }
      return this.quizName;
    };
  }

  public get getForumTitle() {
    return () => {
      if (!this.forumTitle) {
        const route = router.currentRoute.value;

        if (!("forum_record_id" in route.params)) {
          return "";
        }

        forumApi
          .forumRecordRetrieve(route.params.forum_record_id as string)
          .then((res) => {
            BreadcrumbModule.setForumTitle(res.data.title);
            return this.forumTitle;
          });
      }
      return this.forumTitle;
    };
  }

  public get getVideoTitle() {
    return () => {
      return this.videoTitle;
    };
  }

  public get getSupportTitle() {
    return () => {
      if (!this.supportTitle) {
        const route = router.currentRoute.value;

        if (!("support_record_id" in route.params)) {
          return "";
        }

        supportApi
          .supportRecordRetrieve(route.params.support_record_id as string)
          .then((res) => {
            BreadcrumbModule.setSupportTitle(res.data.title);
            return this.supportTitle;
          });
      }
      return this.supportTitle;
    };
  }

  public get getSubmissionName() {
    return () => {
      if (!this.submissionName) {
        const route = router.currentRoute.value;

        if (!("submission_id" in route.params)) {
          return "";
        }

        submissionApi
          .submissionRetrieve(route.params.submission_id as string)
          .then((res) => {
            BreadcrumbModule.setSubmissionName(res.data.id);
            return this.submissionName;
          });
      }

      return this.submissionName;
    };
  }
}

export const BreadcrumbModule = getModule(Breadcrumb);
