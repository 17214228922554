<template>
  <div class="q-pa-md">
    <div class="fit column wrap items-start content-center">
      <div class="text-h5 text-grey-7 self-center text-weight-bold">
        {{ $t("views.password-change.title") }}
      </div>

      <div class="q-pt-md" />
    </div>
    <q-form class="q-gutter-md" @submit="onChangePassword()">
      <div class="q-px-md">
        <q-input
          v-model="passwords.new_password1"
          outlined
          :label="$t('views.password-change.new-password')"
          class="q-pb-md"
        >
          <template #prepend>
            <q-icon name="fas fa-key" size="s" />
          </template>
        </q-input>
        <q-input
          v-model="passwords.new_password2"
          outlined
          :label="$t('views.password-change.new-password-retype')"
          class="q-pb-md"
        >
          <template #prepend>
            <q-icon name="fas fa-key" size="s" />
          </template>
        </q-input>
        <q-btn
          rounded
          style="float: right"
          :label="$t('views.password-change.title')"
          type="submit"
          color="text1"
          class="q-mt-sm"
        />
      </div>
    </q-form>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { Notify } from "quasar";
import { authApi } from "@/api/ilect-openapi-configuration";
import { useI18n } from "vue-i18n";
import { PasswordChange } from "@/openapi-ts/api";

export default defineComponent({
  setup() {
    const $t = useI18n().t;
    const passwords = ref<PasswordChange>({
      new_password1: "",
      new_password2: "",
    });

    async function onChangePassword() {
      try {
        await authApi.authPasswordChangeCreate(passwords.value);
        passwords.value.new_password1 = "";
        passwords.value.new_password2 = "";
        Notify.create({
          type: "positive",
          message: $t("views.password-change.success"),
        });
      } catch (e) {
        console.log(e.response.data);
        const errorMessage = e.response.data["new_password2"];
        Notify.create({
          type: "negative",
          message: errorMessage,
        });
      }
    }

    return {
      passwords,
      onChangePassword,
    };
  },
});
</script>

<style scoped>
.q-btn {
  color: grey;
}
.q-btn:hover {
  color: rgba(57, 163, 223, 1); /* Text color on hover */
}
</style>
