<template>
  <div class="q-pa-md">
    <div class="fit column wrap items-start content-center">
      <div class="text-h5 text-grey-7 self-center text-weight-bold">
        {{ $t("common.signin") }}
      </div>

      <div class="q-pt-md" />

      <q-card
        flat
        bordered
        class="login-card q-py-lg q-mx-auto"
        align="center"
        style="width: 60em"
      >
        <!-- https://developers.google.com/identity/branding-guidelines -->
        <q-card-section>
          <div class="login-buttons">
            <div class="q-pb-md" style="cursor: pointer">
              <q-img
                no-spinner
                :src="googleButtonSrcLink"
                no-transition
                width="15em"
                @click="loginWithGoogle"
                @mouseover="googleButtonMouseOver = true"
                @mouseleave="googleButtonMouseOver = false"
              />
            </div>
          </div>

          <div class="q-pa-md text-grey-7">
            <p align="center">
              {{ $t("views.signin.google_message") }}
              <br />
              <q-img
                style="width: 15em; height: auto"
                src="login_drive_checkbox.png"
              />
            </p>
            <p align="center">
              {{ $t("views.signin.google_message2") }}
            </p>
          </div>
        </q-card-section>

        <SeparatorWithText :text="$t('views.signin.separator')" />

        <q-card-section class="column">
          <email-login />
        </q-card-section>
      </q-card>
    </div>
  </div>
</template>

<script lang="ts">
import { useGapi } from "@/utils/gapi-wrapper";
import { useQuasar } from "quasar";
import { defineComponent, ref, watch } from "vue";
import { AuthModule } from "@/store/modules/auth";
import { useRouter } from "vue-router";
import EmailLogin from "@/components/auth/EmailLogin.vue";
import SeparatorWithText from "@/components/SeparatorWithText.vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "LoginView",

  components: { SeparatorWithText, EmailLogin },

  setup() {
    const $gapi = useGapi();
    const $q = useQuasar();
    const $router = useRouter();
    const $t = useI18n().t;
    const loading = ref(false);
    const googleButtonMouseOver = ref(false);
    const googleButtonSrcLink = ref(
      "btn_google_signin_light_normal_web@2x.png",
    );

    const isEmailLoginClicked = ref(false);

    function updateGoogleButtonSrcLink() {
      if (AuthModule.isDarkmode) {
        if (googleButtonMouseOver.value) {
          googleButtonSrcLink.value =
            "btn_google_signin_dark_pressed_web@2x.png";
        } else {
          googleButtonSrcLink.value =
            "btn_google_signin_dark_normal_web@2x.png";
        }
      } else {
        if (googleButtonMouseOver.value) {
          googleButtonSrcLink.value =
            "btn_google_signin_light_pressed_web@2x.png";
        } else {
          googleButtonSrcLink.value =
            "btn_google_signin_light_normal_web@2x.png";
        }
      }
    }

    updateGoogleButtonSrcLink();

    // Watcher can only detect getter change
    watch([AuthModule.getDarkMode, googleButtonMouseOver], () => {
      updateGoogleButtonSrcLink();
    });

    function loginWithGoogle() {
      const loadingNotify = $q.notify({
        type: "ongoing",
        message: $t("common.processing"),
      });

      loading.value = true;

      AuthModule.loginWithGoogle({ gapi: $gapi })
        .then(() => {
          loadingNotify({
            type: "positive",
            message: $t("messages.information.loginSuccess"),
          });

          AuthModule.postLogin()
            .then(() => {
              $router.push({
                name: "Course",
                replace: true,
              });
            })
            .catch((e) => {
              return Promise.reject(e);
            });
        })
        .catch((error) => {
          console.error(error);
          loadingNotify({
            type: "negative",
            message: $t("messages.error.loginFailed"),
          });
        })
        .finally(() => {
          loading.value = false;
        });
    }

    return {
      loginWithGoogle,
      loading,
      AuthModule,
      googleButtonMouseOver,
      googleButtonSrcLink,
      isEmailLoginClicked,
    };
  },
});
</script>
