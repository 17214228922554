import { BreadcrumbModule } from "@/store/modules/breadcrumbs";
import router from "@/router/index";

export interface Breadcrumb {
  label: string;
  to?: string | null;
  icon: string | null;
}

export interface BreadcrumbGenerator {
  (): Breadcrumb[];
}

// Provide default templates
// router is not available from routes.

export const courseBreadcrumb: Breadcrumb = {
  label: "Course",
  to: "/course",
  icon: "class",
};

export function assessmentTableBreadcrumbGenerator() {
  const route = router.currentRoute.value;
  return {
    label: "Assessment",
    icon: "assessmentTable",
    to: `/course/${route.params.course_id}/assessment`,
  };
}

export function assessmentDetailBreadcrumbGenerator() {
  const route = router.currentRoute.value;
  return {
    label: `${route.params.user_id}`,
    icon: "assessmentDetail",
    to: `/course/${route.params.course_id}/assessment/${route.params.user_id}`,
  };
}

export function assignmentBreadcrumbGenerator() {
  const route = router.currentRoute.value;
  return {
    label: "Assignment",
    icon: "assignment",
    to: `/course/${route.params.course_id}/assignment`,
  };
}

export function assignmentDetailBreadcrumbGenerator() {
  const route = router.currentRoute.value;
  return {
    label: BreadcrumbModule.getAssignmentName(),
    icon: "",
    to: `/course/${route.params.course_id}/assignment/${route.params.assignment_id}`,
  };
}

export function quizDetailBreadcrumbGenerator() {
  const route = router.currentRoute.value;
  return {
    label: BreadcrumbModule.getQuizName(),
    icon: "",
    to: `/course/${route.params.course_id}/quiz/${route.params.quiz_id}`,
  };
}

export function submissionBreadcrumbGenerator() {
  const route = router.currentRoute.value;
  return {
    label: "Submission",
    icon: "ballot",
    to: `/course/${route.params.course_id}/submission`,
  };
}

export function courseForumBreadcrumbGenerator() {
  const route = router.currentRoute.value;
  return {
    label: "Forum",
    icon: "textsms",
    to: `/course/${route.params.course_id}/forum`,
  };
}

export function videoListBreadcrumbGenerator() {
  const route = router.currentRoute.value;
  return {
    label: "Videos",
    icon: "textsms",
    to: `/course/${route.params.course_id}/video`,
  };
}

export function supportDetailBreadcrumbGenerator() {
  return {
    label: "Support",
    icon: "settings",
    to: `/support/`,
  };
}

// Breadcrumbs inside a course
export function breadcrumbsUntilCourseDetail(): Breadcrumb[] {
  const route = router.currentRoute.value;
  return [
    courseBreadcrumb,
    {
      label: BreadcrumbModule.getCourseName(),
      to: `/course/${route.params.course_id}/workbook`,
      icon: null,
    },
  ];
}
