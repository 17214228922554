import "./styles/quasar.sass";
import "@quasar/extras/material-icons/material-icons.css";
import "@quasar/extras/fontawesome-v5/fontawesome-v5.css";
import { Notify, Dialog, Loading, QVideo } from "quasar";

// To be used on app.use(Quasar, { ... })
export default {
  plugins: {
    Notify,
    Dialog,
    Loading,
    QVideo,
  },
  extras: ["material-icons", "fontawesome-v6"],
};
