<template>
  <q-select
    v-model="locale"
    class="text-white"
    dark
    :options="localeOptions"
    text-white
    borderless
    emit-value
    map-options
  />
</template>

<script>
import { useI18n } from "vue-i18n";
import languages from "quasar/lang/index.json";
import { watch } from "vue";
import { AuthModule } from "@/store/modules/auth";

const appLanguages = languages.filter((lang) =>
  ["ja", "en-US"].includes(lang.isoName),
);
const langOptions = appLanguages.map((lang) => ({
  label: lang.nativeName,
  value: lang.isoName,
}));

export default {
  setup() {
    const { locale } = useI18n({ useScope: "global" });
    watch(locale, (val) => {
      AuthModule.setDisplayLang(val);
    });
    return {
      locale,
      localeOptions: langOptions,
    };
  },
};
</script>
