<template>
  <div class="logo_container">
    <img height="25" :src="`${publicPath}ilect_logo.svg`" alt="Nablas Inc" />
    <span>iLect</span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  setup() {
    return {
      publicPath: import.meta.env.BASE_URL,
    };
  },
});
</script>

<style>
.logo_container {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  font-weight: 700;
  font-size: 1.6em;
}
</style>
