<template>
  <div class="column vertical-middle">
    <div class="row justify-center q-ma-md q-mt-xl">
      <div class="text-h5">
        {{ $t("views.notfound.message") }}
      </div>
    </div>
    <div class="row justify-center q-ma-md">
      <router-link to="/Course">
        <q-btn class="relative" color="primary">
          {{ $t("views.notfound.button") }}
        </q-btn>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotFound",
};
</script>
