<template>
  <div class="column vertical-middle">
    <div class="row justify-center q-ma-md q-mt-xl">
      <div class="text-h5">
        {{ $t("views.logout.message") }}
      </div>
    </div>
    <div class="row justify-center q-ma-md">
      <router-link to="/login">
        <q-btn class="relative" color="primary">
          {{ $t("views.logout.login") }}
        </q-btn>
      </router-link>
    </div>
  </div>
</template>

<script>
import { useGapi } from "@/utils/gapi-wrapper";
import { onMounted } from "vue";
import { useQuasar } from "quasar";
import { AuthModule } from "@/store/modules/auth";

export default {
  name: "LogoutView",

  setup() {
    const $q = useQuasar();
    const $gapi = useGapi();

    onMounted(() => {
      const loadingNotify = $q.notify({
        type: "ongoing",
        message: "Processing...",
      });

      $gapi
        .logout()
        .then(() => {
          loadingNotify({
            type: "positive",
            message: "Logout succeeded.",
          });
        })
        .catch(() => {
          loadingNotify({
            type: "negative",
            message: "Logout failed.",
          });
        })
        .finally(() => {
          AuthModule.resetToken();
        });
    });
  },
};
</script>
